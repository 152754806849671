import React from 'react';
import { menu_list } from './assets/assets';
import styled from 'styled-components';

const CategoryButton = styled.button`
  padding: 8px 16px;
  height: 30px;
  margin: 10px 10px 0 0;
  border: none;
  border-radius: 15px;
  background-color: ${(props) => props.selected ? '#FEA150' : '#F2F2F2'};
  color: ${(props) => props.selected ? '#FFF' : '#000'};
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: ${(props) => props.selected ? '#FEA150' : ' rgba(254, 161, 80, 0.3)'};
  }
`;

const Exploremenu = ({ category, setCategory }) => {
    return (
        <div id='explore-menu' style={{ marginLeft: '10%' }}>
            {menu_list.map((item, index) => {
                return (
                    <CategoryButton
                        selected={category === item.menu_name}
                        key={index}
                        onClick={() => setCategory(prev => prev === item.menu_name ? 'All' : item.menu_name)}>
                        {item.menu_name}
                    </CategoryButton>
                );
            })}
        </div>
    );
};

export default Exploremenu;
