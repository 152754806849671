import React, { useState, useEffect } from 'react';
import './Review.css';
import axios from 'axios';

const Review = () => {
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [cartItemsDetails, setCartItemsDetails] = useState([]);
  const [orderId, setOrderId] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [token, setToken] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    const urlParams = new URLSearchParams(window.location.search);
    const orderIdFromUrl = urlParams.get('orderId');
    if (orderIdFromUrl) {
      setOrderId(orderIdFromUrl);
      fetchOrderDetails(orderIdFromUrl);
    }

    // Check if a review token is stored locally
    const storedToken = localStorage.getItem('reviewToken');
    if (storedToken) {
      setToken(storedToken);

      // Set a timeout to remove the token after 1 hour
      const oneHour = 60 * 60 * 1000; // 1 hour in milliseconds
      const cleanupTimeout = setTimeout(() => {
        localStorage.removeItem('reviewToken');
        setToken('');
      }, oneHour);

      // Cleanup the timeout when the component unmounts or the token changes
      return () => clearTimeout(cleanupTimeout);
    }
  }, []);

  const fetchOrderDetails = async (orderId) => {
    try {
      const response = await axios.get(`https://andhraruchulu.onrender.com/placeorder/${orderId}/details`);
      if (response.data.success) {
        setOrderDetails(response.data.orderDetails);
        setCartItemsDetails(response.data.orderDetails.cartItems);
        setName(response.data.orderDetails.name);
      } else {
        console.error('Failed to fetch order details:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching order details:', error);
    }
  };

  const handleRatingClick = (value) => {
    setRating(value);
  };

  const generateToken = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const tokenLength = 16;
    let token = '';
    for (let i = 0; i < tokenLength; i++) {
      token += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return token;
  };

  const handleSubmitReview = async () => {
    setLoading(true);
    try {
      const response = await axios.post('https://andhraruchulu.onrender.com/submitreview', {
        name,
        orderId,
        rating,
        feedback
      });
      if (response.data.success) {
        const newToken = generateToken();
        setToken(newToken);
        setSubmitted(true);
        // Save the token locally
        localStorage.setItem('reviewToken', newToken);
      } else {
        console.error('Failed to submit review:', response.data.error);
      }
    } catch (error) {
      console.error('Error submitting review:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='login-popup'>
      <div className='login-popup-container'>
        <i className='b1'></i>
        <i className='b3'></i>
        <div className='login'>
          {localStorage.getItem('reviewToken') && (
            <div className="thankyou-message" style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <text style={{ marginBottom: '20px' }}> Thank you for the review!</text>
              <button className="button" onClick={() => {
                window.location.href = 'https://andhraruchulu.in';
              }}>Go to Website</button>
            </div>
          )}
          {!localStorage.getItem('reviewToken') && (
            <>
              <h3>Review Us</h3>
              <div style={{ marginTop: '-15px' }}>
                {[...Array(5)].map((star, index) => {
                  const ratingValue = index + 1;
                  return (
                    <span
                      key={ratingValue}
                      className={ratingValue <= rating ? 'star filled' : 'star'}
                      onClick={() => handleRatingClick(ratingValue)}
                      style={{ cursor: 'pointer', color: ratingValue <= rating ? '#FEA150' : '#CECDCC', fontSize: '30px' }}
                    >
                      ★
                    </span>
                  );
                })}
              </div>
              {cartItemsDetails.length > 0 ? (<div style={{ marginTop: '-30px', justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <h4>Your Order Items</h4>
                <div style={{ marginTop: '-20px', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                  {cartItemsDetails.map((item, index) => (
                    <text key={index}>
                      {item.name} {','}</text>

                  ))}
                </div>
              </div>) : <></>}
              <textarea
                className="form-control"
                id="feedback"
                rows="2"
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
              ></textarea>
              {loading ? (
                <div className="loading-container">
                  <div className="loader"></div>
                </div>
              ) : (
                <button type="submit" className="button" onClick={handleSubmitReview}>
                  Submit Review
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Review;
