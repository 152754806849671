import React, { useContext, useEffect } from 'react';
import img3 from '../components/assets/cart.png';
import './cart.css';
import { useNavigate } from 'react-router-dom';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import styled from 'styled-components';
import { Storecontext } from '../contex/Storecontext';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const MenuItem = styled.div`
  height: 90vh;
  overflow-y: scroll; 

  &::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width: 800px) {
    overflow: auto; 
  }
`;

const MenuItem1 = styled.div`
  display: flex;
  gap: 20px;
  
  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

const BackButton = styled.div`
  display: none;
  @media only screen and (max-width: 800px) {
    display: block;
    width: 20px;
  }
`;


const Buttonlogin1 = styled.div`
width: max(15vw, 200px);
 
`;

const PlusButton = styled.button`
  border: none;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: #eaeaea;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &:hover {
    background-color: rgba(254, 161, 80, 1.0);
    color: #fff;
  }
`;

const Cart = () => {
    const navigate = useNavigate();
    const { cartItems, food_list, addToCart, removeFromCart, getTotalCartAmount } = useContext(Storecontext);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleClick = () => {
        navigate('/order');
    };

    const handleLoginClick = () => {
        navigate('/login');
    };
    const handleClickback = () => {
        navigate('/new');
    };


    const allItemsRemoved = Object.values(cartItems).every(quantity => quantity <= 0);

    return (
        <MenuItem>
            {allItemsRemoved ? (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                    <p>Please, feel free to add items to your cart.</p>
                    <img src={img3} alt='' style={{ width: '20%', marginTop: '20px' }} />
                </div>
            ) : (
                <div className='cart'>
                    <BackButton>
                        <ArrowBackIcon onClick={handleClickback}  style={{ width: '20px' }}/>
                    </BackButton>
                    <div className='cart-items'>
                        <div className='cart-items-title'>
                            <p>Items</p>
                            <p>Title</p>
                            <p>Price</p>
                            <p>Quantity</p>
                            <p>Total</p>
                            <p>Add</p>
                            <p>Remove</p>
                        </div>
                        <br />
                        <hr />
                        {food_list.map((item, index) => {
                            if (cartItems[item._id] > 0) {
                                return (
                                    <div key={index} className='cart-items-title cart-items-item'>
                                        <img src={item.image} alt='' />
                                        <p>{item.name}</p>
                                        <p>€{item.price}</p>
                                        <p>{cartItems[item._id]}</p>
                                        <p>€{item.price * cartItems[item._id]}</p>
                                        <PlusButton onClick={() => addToCart(item._id)}>
                                            <AddIcon style={{ width: '15px' }} />
                                        </PlusButton>
                                        <PlusButton onClick={() => removeFromCart(item._id)}>
                                            <ClearOutlinedIcon style={{ width: '15px' }} />
                                        </PlusButton>
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </div>
                    <div className='cart-bottom'>
                        <div className='cart-total'>
                            <h2>Cart Total</h2>
                            <div>
                                <div className='cart-total-details'>
                                    <p>Subtotal</p>
                                    <p>€{getTotalCartAmount()}</p>
                                </div>
                                <hr />
                                <div className='cart-total-details'>
                                    <p>Delivery Fee</p>
                                    <p>€{0}</p>
                                </div>
                                <hr />
                                <div className='cart-total-details-total'>
                                    <b>Total</b>
                                    <b>€{getTotalCartAmount() + 0}</b>
                                </div>
                            </div>
                            <MenuItem1 >
                                {localStorage.getItem('auth-token') ? (
                                    <button onClick={handleClick}>PROCEED TO CHECKOUT</button>
                                ) : (
                                    <button onClick={handleLoginClick}>Please Login</button>
                                )}
                                {localStorage.getItem('auth-token') ? (
                                    <></>
                                ) : (
                                    <Buttonlogin1 className='buttoneirdisableee' onClick={handleClick}>Proceed Without Login</Buttonlogin1>
                                )}
                            </MenuItem1>


                        </div>
                        {/* <div className='cart-promocode'>
                            <div>
                                <p>If you have a promo code, Enter it here</p>
                                <div className='cart-promocode-input'>
                                    <input type='text' placeholder='promo code'></input>
                                    <button>Submit</button>
                                </div>
                            </div>

                        </div> */}
                    </div>
                </div>
            )}
        </MenuItem>
    );
};

export default Cart;
