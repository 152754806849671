import React, { useEffect, useState } from 'react';
import img1 from '../components/assets/chef.png';
import img2 from '../components/assets/contact.png';
import img3 from '../components/assets/pur.png';
import '../pages/Aboutus.css';
import axios from 'axios';

const Aboutus = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [feedback, setFeedback] = useState('');
  const [loading, setLoading] = useState(false);


  const handleSubmit = async () => {
    try {

      if (!name) {
        alert("Name cannot be empty");
        return;
      }
      if (!email) {
        alert("Email cannot be empty");
        return;
      }
      if (!feedback) {
        alert("Feedback cannot be empty");
        return;
      }

      if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        alert("Please enter a valid email address");
        return;
      }


      setLoading(true);
      console.log("Name:", name);
      console.log("Email:", email);
      console.log("Feedback:", feedback);

      await axios.post('https://andhraruchulu.onrender.com/feedback', { name, email, feedback });

      setName('');
      setEmail('');
      setFeedback('');
    } catch (error) {
      console.error("Error submitting feedback:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="container" style={{ backgroundColor: '#ffffff' }}>
        <div className="container1">
          <div className="text-container">
            <h3>Where Taste Meets Purpose!</h3>
            <p>"At <span className="highlight" >Andhra Ruchulu</span> , we passionately showcase the rich flavors of Telugu States through authentic biryanis and curries. Each dish embodies tradition, quality, and a commitment to culinary excellence."</p>

          </div>
          <div className="image-container">
            <img src={img3} alt='' />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="container1">
          <div className="image-container">
            <img src={img1} alt='' />
          </div>
          <div className="text-container">
            <h3>About Us</h3>
            <p>Calling all Biryani Believers!</p>
            <p>Here's <span className="highlight" >Sai Malla</span>, the Biryani whisperer!   (Okay, maybe not a whisperer, the aromas tend to give it away!)</p>
            <p>Growing up in a village surrounded by Grandma's legendary cooking, I always wondered – what's her secret spice? Turns out, it's a big ol' dollop of love!   She cooked to nourish souls, not just bellies, and that's the magic I bring to my kitchen too.</p>
            <p>Tired of the daily hustle leaving you hangry?   Fear not, my friends! My Biryani is here to be your stress-busting yoga session (minus the weird pretzel poses).</p>
            <p>Come join my ever-growing circle of happy eaters!  Let's dive into some seriously delicious Andhra Ruchulu Biryani and Chicken – I promise the love factor will have your taste buds chanting "OM nom nom!"</p>
            <p>See you soon, fellow foodies!</p>
          </div>
        </div>
      </div>
      <div className="container" style={{ backgroundColor: '#ffffff' }}>
        <div className="container1">
          <div className="textcontainer">
            <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
            <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            <textarea
              placeholder="Your Feedback"
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              rows={5}
            />
            <button className='Buttongetc' onClick={handleSubmit}>Submit Feedback</button>
          </div>
          <div className="image-container">
            <img src={img2} alt='' width='70%' />
          </div>
        </div>
      </div>
      {loading && <div className="loading-overlay"><div className="loading-animation"></div></div>}
    </>
  );
};

export default Aboutus;
