import React, { useContext, useEffect, useRef } from 'react'
import { Storecontext } from '../contex/Storecontext';
import './cart.css';
import './Placeorder.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const MenuItem = styled.div`
  height: 90vh;
  
  @media only screen and (max-width: 800px) {
    height: 100%; 
  }
`;

const Placeorder = () => {
    const [selectedDate, setSelectedDate] = useState(null);
    const { food_list, getTotalCartAmount, cartItems } = useContext(Storecontext);
    const [isAvailable, setIsAvailable] = useState(false);
    const [availability, setAvailability] = useState(true);
    const [loading, setLoading] = useState(false);
    const [delivery, setDelivery] = useState(true);
    const [button, setButton] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        eirCode: '',
        address: '',
        phoneNumber: '',
        deliveryDate: null,
        cartItems: [],
        totalAmount: 0,
        ordertype: '',
    });
    const [triggeredField, setTriggeredField] = useState(null);
    const [excludedDates, setExcludedDates] = useState([]);

    const nameInputRef = useRef(null);
    const emailInputRef = useRef(null);
    const eirCodeInputRef = useRef(null);
    const addressInputRef = useRef(null);
    const phoneNumberInputRef = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0);
        const savedEIRCode = localStorage.getItem('eirCode');
        const savedOrderType = localStorage.getItem('type');
        if (savedEIRCode) {
            setFormData(prevState => ({
                ...prevState,
                eirCode: savedEIRCode,
                ordertype: savedOrderType
            }));
            const firstThreeChars = savedEIRCode.toUpperCase().substring(0, 3);
            setIsAvailable(firstThreeChars === 'C15');
            setAvailability(isAvailable);
        }
        fetchSelectedDates();
    }, []);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (name === "eirCode" && (value.length === 7 || value.length === 8)) {
            localStorage.setItem('eirCode', value);
            if (value.toUpperCase().substring(0, 3) === 'C15') {
                localStorage.setItem('type', "delivery");
            }
            else {
                localStorage.setItem('type', "pickup");
            }
        }
        setTriggeredField(null);
    };


    const placeOrderdata = async (e) => {
        e.preventDefault();

        for (const key in formData) {
            if (
                formData.hasOwnProperty(key) &&
                ((formData[key] === '' && (key !== 'address' || localStorage.getItem('type') === 'delivery')) || (key === 'deliveryDate' && formData[key] === null))
            ) {
                alert(`${key} cannot be empty`);
                setTriggeredField(key);
                return;
            }
        }

        setLoading(true);

        const totalAmount = getTotalCartAmount();
        const cartItemsWithNames = Object.entries(cartItems)
            .filter(([itemId, quantity]) => quantity > 0)
            .map(([itemId, quantity]) => {
                const itemInfo = food_list.find(product => product._id === Number(itemId));
                return {
                    name: itemInfo.name,
                    quantity: quantity
                };
            });

        const ordertype = localStorage.getItem('type');

        const formDataWithCart = {
            ...formData,
            cartItems: cartItemsWithNames,
            totalAmount: totalAmount,
            ordertype: ordertype
        };

        try {
            const response = await axios.post('https://andhraruchulu.onrender.com/placeorder', formDataWithCart);
            const responseData = response.data;
            console.log("Response from server:", responseData);
            if (responseData.success) {
                console.log('Order placed successfully:', responseData);
                if (localStorage.getItem('auth-token')) {
                    await fetch('https://andhraruchulu.onrender.com/removeallfromcart', {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'auth-token': localStorage.getItem('auth-token'),
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ cartItems: Object.keys(cartItems).filter(itemId => cartItems[itemId] > 0) })
                    });
                }
                localStorage.setItem('review-cart-items', JSON.stringify(cartItemsWithNames));
                window.location.href = "/address";
                localStorage.removeItem('cart-items');
            } else {
                alert(responseData.error);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDateChange = date => {
        const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        setSelectedDate(date);
        setFormData({ ...formData, deliveryDate: utcDate.toISOString() });
    };

    const checkEIRAvailability = (eirCode) => {
        const firstThreeChars = eirCode.toUpperCase().substring(0, 3);
        setIsAvailable(firstThreeChars === 'C15');
    };
    const handledelivery = () => {
        localStorage.setItem('type', "delivery");
        window.location.href = "/order";
    };

    const handlepickup = () => {
        localStorage.setItem('type', "pickup");
        window.location.href = "/order";
    };

    // Fetch selected dates from the backend
    const fetchSelectedDates = async () => {
        try {
            const response = await axios.get('https://andhraruchulu.onrender.com/selecteddates');
            const { selectedDates } = response.data;
            setExcludedDates(selectedDates.map(date => new Date(date)));
        } catch (error) {
            console.error('Error fetching selected dates:', error);
        }
    };


    return (
        <MenuItem >
            <form className='place-order'>
                <div className="place-order-left">
                    <p className="title">Delivery Information</p>
                    <input type="text" name="eirCode" placeholder='EIR code' value={formData.eirCode} onChange={(e) => {
                        handleInputChange(e);
                        checkEIRAvailability(e.target.value);
                    }} required
                        ref={eirCodeInputRef}
                        style={{ textTransform: 'uppercase', border: triggeredField === 'eirCode' ? '1px solid red' : '' }} />
                    {(formData.eirCode.length >= 7 && formData.eirCode.length <= 8) ? (
                        isAvailable ? (
                            localStorage.getItem('type') === 'delivery' ? (
                                <div style={{ display: 'flex', gap: '20px', marginTop: '-7px', marginBottom: '15px' }}>
                                    <button className='buttoneirdisable' onClick={handlepickup}>Pick Up</button>
                                    <button className='buttoneircheck' onClick={handledelivery}>Delivery</button>
                                </div>
                            ) : (
                                <div style={{ display: 'flex', gap: '20px', marginTop: '-7px', marginBottom: '15px' }}>
                                    <button className='buttoneircheck' onClick={handlepickup}>Pick Up</button>
                                    <button className='buttoneirdisable' onClick={handledelivery}>Delivery</button>
                                </div>
                            )

                        ) : (
                            <div style={{ display: 'flex', gap: '20px', marginTop: '-7px', marginBottom: '15px' }}>
                                <button className='buttoneircheck' onClick={handlepickup}>Pick Up</button>
                                <button className='buttoneirdisablep' disabled>Delivery</button>
                            </div>
                        )
                    ) : (
                        <div style={{ display: 'flex', gap: '20px', marginTop: '-7px', marginBottom: '15px' }}>
                            <button className='buttoneirdisablep' disabled>Pick Up</button>
                            <button className='buttoneirdisablep' disabled>Delivery</button>
                        </div>
                    )}
                    {localStorage.getItem('type') !== '' && formData.eirCode.length >= 7 && formData.eirCode.length <= 8 ? (
                        <div>
                            <DatePicker
                                selected={selectedDate}
                                onChange={(date) => handleDateChange(date)}
                                placeholderText="Pick Your Perfect Day!"
                                className="custom-datepicker"
                                minDate={new Date()}
                                includeDates={excludedDates} // Exclude selected dates from being selectable
                                onFocus={(e) => (e.target.readOnly = true)}
                            />
                            <input
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={formData.name}
                                onChange={handleInputChange}
                                ref={nameInputRef}
                                style={{ border: triggeredField === 'name' ? '1px solid red' : '' }}
                            />
                            <input
                                type="email"
                                name="email"
                                placeholder="Email address"
                                value={formData.email}
                                onChange={handleInputChange}
                                ref={emailInputRef}
                                style={{ border: triggeredField === 'email' ? '1px solid red' : '' }}
                            />
                            {localStorage.getItem('type') === 'delivery' ? (
                                <input
                                    type="text"
                                    name="address"
                                    placeholder="Address"
                                    value={formData.address}
                                    onChange={handleInputChange}
                                    ref={addressInputRef}
                                    style={{ border: triggeredField === 'address' ? '1px solid red' : '' }}
                                />
                            ) : (
                                <></>
                            )}
                            <input
                                type="text"
                                name="phoneNumber"
                                placeholder="Phone number"
                                value={formData.phoneNumber}
                                onChange={handleInputChange}
                                ref={phoneNumberInputRef}
                                style={{ border: triggeredField === 'phoneNumber' ? '1px solid red' : '' }}
                            />
                        </div>
                    ) : (
                        <></>
                    )}


                    {/* <button className='place-order-left-button' >SAVE ADDRESS</button> */}
                </div>
                <div className="place-order-right">
                    <div className='cart-total'>
                        <h2 style={{ fontSize: '24px', fontWeight: '600' }}>Cart Total</h2>
                        <div>
                            <div className='cart-total-details'>
                                <p>Subtotal</p>
                                <p>€{getTotalCartAmount()}</p>
                            </div>
                            <hr />
                            <div className='cart-total-details'>
                                <p>Delivery Fee</p>
                                <p>€{0}</p>
                            </div>
                            <hr />
                            <div className='cart-total-details-total'>
                                <b>Total</b>
                                <b>€{getTotalCartAmount() + 0}</b>
                            </div>
                        </div>
                        <button onClick={placeOrderdata} disabled={loading}>PLACE ORDER</button>
                    </div>
                </div>
            </form>
            {loading && <div className="loading-overlay"><div className="loading-animation"></div></div>}
        </MenuItem>

    )
}

export default Placeorder
