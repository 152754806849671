import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./Navbar.css";
import logoimg from '../components/assets/logoe.png';
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@mui/icons-material/Menu';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Divider from '@mui/material/Divider';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Storecontext } from '../contex/Storecontext';
import img3 from '../components/assets/profile.png';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    left: {
        float: 'left',
        display: 'flex',
        alignItems: 'center',
    },
    right: {
        float: 'right',
        display: 'flex',
        alignItems: 'center',
    },
    line: {
        marginRight: '10px',
        [theme.breakpoints.down("md")]: {
            display: 'none',
        },
    },
    menu: {
        display: 'none',
        [theme.breakpoints.down("md")]: {
            display: 'flex',
            marginRight: '8%',
        },
    },
    hide: {
        display: "none",
    },
    Dialog: {
        [theme.breakpoints.up("md")]: {
            display: 'none',
        },
    },
}));

const styles = {
    dialogContainer: {
        minWidth: '300px',
        minHeight: '200px',
    },
    closeButton: {

        marginLeft: '10%',
        marginTop: '10px',
    },
    listItemButton: {
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '5%',
        border: 'none',
        color: 'white',
        backgroundColor: '#FEA150',
        minWidth: '200px', // Adjust width as needed
        padding: '12px 24px',
        borderRadius: '20px',
        cursor: 'pointer',
        transition: '0.5s',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: 'linear-gradient(45deg, var(--purple), var(--yellow))',
        '&:hover': {
            filter: 'drop-shadow(0 0 4px #FF8C28)',
        },
    },
};


const Navbar = ({ isLoggedIn, onLogout }) => {
    const { getTotalCartItems } = useContext(Storecontext);
    const classes = useStyles();
    const navigate = useNavigate();
    const [navbar, setNavbar] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [open1, setOpen1] = React.useState(false);

    const handleDrawerOpen1 = () => {
        setOpen1(true);
    };
    const handleClose1 = () => {
        setOpen1(false);
    };

    const handleProfileClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleLoginClick = () => {
        navigate('/login');
    };

    const handleLoginClick2 = () => {
        navigate('/login');
        setOpen1(false);
    };

    const handleClick = () => {
        navigate('/');
    };

    const handleClick1 = () => {
        navigate('/');
        setOpen1(false);
    };
    const handleCartClick = () => {
        navigate('/cart');
    };

    const handlemenuClick = () => {
        navigate('/new');
    };

    const handlemenuClick1 = () => {
        navigate('/cart');
        setOpen1(false);
    };

    const handlemenuClick2 = () => {
        navigate('/new');
        setOpen1(false);
    };

    const handlemenuClickabout = () => {
        navigate('/aboutus');
    };

    const handlemenuClickabout1 = () => {
        navigate('/aboutus');
        setOpen1(false);
    };


    const handleLogout = () => {
        onLogout(); // Call 
        setOpenDialog(false);
        localStorage.removeItem('auth-token')
        localStorage.removeItem('cart-items');
        window.location.reload();
    };


    const changeboxshadow = () => {
        if (window.scrollY >= 30) {
            setNavbar(true)
        }
        else {
            setNavbar(false)
        }
    };

    window.addEventListener('scroll', changeboxshadow);

    return (
        <div className={navbar ? 'navbar scrolled' : 'navbar'} >
            <div className={classes.left} style={{ display: 'flex', alignItems: 'center' }}>
                <img src={logoimg} height='28' width='auto' style={{ marginLeft: '20px', marginTop: '10px', marginRight: '-5px' }} alt='' />
                <text onClick={handleClick} className='Logotext'>Andhra Ruchulu</text>
            </div>
            <div className={classes.right}>

                <text className='MenuItem' onClick={handlemenuClick} >Menu</text>
                <text className='MenuItem' onClick={handlemenuClickabout}>About Us</text>

                <text className={classes.line}>|</text>
                {getTotalCartItems() === 0 ? (
                    <IconButton style={{ marginLeft: '10px', marginRight: '20px' }} color="inherit" onClick={handleCartClick} className={classes.cartButton}>
                        <LocalMallOutlinedIcon fontSize="small" />
                    </IconButton>
                ) : (
                    <div style={{ position: 'relative', marginLeft: '20px', marginRight: '30px' }}>
                        <LocalMallOutlinedIcon fontSize="small" style={{ color: 'black' }} onClick={handleCartClick} />
                        <div style={{ position: 'absolute', top: -8, right: -8, backgroundColor: 'orange', color: 'white', borderRadius: '50%', padding: '2px 6px', fontSize: '10px' }}>{getTotalCartItems()}</div>
                    </div>
                )}

                {localStorage.getItem('auth-token') ? (
                    <div className="ProfileIconContainer" onClick={handleProfileClick}>
                        <img src={img3} alt="Profile Icon" width='30px' />
                    </div>

                ) : (
                    <div className='Buttonget' onClick={handleLoginClick}>Log In</div>
                )}

                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    PaperProps={{
                        style: styles.dialogContainer,
                    }}
                >
                    <div style={styles.closeButton}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCloseDialog}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <List>
                        <ListItem button onClick={handleLogout} style={styles.listItemButton}>
                            <ListItemText primary="Logout"  style={{marginLeft:'37%', }}/>
                        </ListItem>
                    </List>
                </Dialog>

                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen1}
                    edge="start"
                    className={classes.menu}
                >
                    <MenuIcon />
                </IconButton>

                <Dialog
                    // className={classes.Dialog}
                    fullScreen
                    open={open1}
                    onClose={handleClose1}
                    TransitionComponent={Transition}
                >
                    <div style={{ display: 'flex', justifyItems: 'start', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex' }} >
                            <img src={logoimg} height='18' width='auto' style={{ marginLeft: '10%', marginTop: "18px" }} alt='' />
                            <text onClick={handleClick1} className='Logotext'>Andhra Ruchulu</text>
                        </div>
                        <div>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose1}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                    <Divider />
                    <List style={{ marginLeft: '5%', marginTop: '5%', justifyContent: 'space-between' }}>
                        {open1 ? (
                            <div className="ProfileIconContainer">
                            </div>
                        ) : (
                            <></>
                        )}
                        <text className='MenuItems' onClick={handlemenuClick2} >Menu</text>
                        <text className='MenuItems' onClick={handlemenuClickabout1}>About Us</text>
                        <text className='MenuItems' onClick={handlemenuClick1}>Cart</text>

                        {localStorage.getItem('auth-token') ? (
                            <div className='Buttongeto' onClick={handleLogout}>Log out</div>
                        ) : (
                            <div className='Buttongeto' onClick={handleLoginClick2}>Log In</div>
                        )}
                    </List>
                </Dialog>

            </div>
        </div >
    )
}

export default Navbar;
