import React, { useEffect, useContext, useState } from 'react';
import Exploremenu from '../components/Exploremenu';
import Fooddisplay from '../components/Fooddisplay';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Storecontext } from '../contex/Storecontext';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  position: fixed;
  bottom: 0;
  width: 25%;
  height: 10%;
  left: 50%;
  display: flex; 
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  transform: translateX(-50%);
  background-color: #F0D4BB;
  border-radius: 40px; 
  @media only screen and (max-width: 800px) {
    width: 80%;
    border-radius: 15px; 
  }
`;

const Container2 = styled.div`
  height: 10%;
  @media only screen and (max-width: 800px) {
    height: 20%;
  }
`;


const MenuItem = styled.div`
  height: 90vh;
  overflow: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  
  @media only screen and (max-width: 800px) {
    overflow: auto; 
  }
`;

const PlusButtonadd = styled.button`
  border: none;
  height:auto;
  width:auto;
  border-radius: 20px;
  padding: 5px 10px;
  background: #FEA150;
  color: #fff; 
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;

`;


const Newmenu = () => {
  const { getTotalCartItems } = useContext(Storecontext);
  const location = useLocation();
  const [category, setCategory] = useState('All');
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/cart');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.state && location.state.category) {
      setCategory(location.state.category);
    }
  }, [location.state]);

  return (
    <div>
      <MenuItem>
        <Exploremenu category={category} setCategory={setCategory} />
        <Fooddisplay category={category} />
        <Container2 />
      </MenuItem>
      {getTotalCartItems() === 0 ? <></> : <Container onClick={handleClick}>
        <text style={{ marginLeft: '10%' }}>{getTotalCartItems()} items added</text>
        <PlusButtonadd style={{ marginRight: '10%' }} onClick={handleClick}>
          View Cart
        </PlusButtonadd>
      </Container>}

    </div>
  );
};

export default Newmenu;
