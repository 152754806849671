import React, { useContext } from 'react'
import { Storecontext } from '../contex/Storecontext'
import FoodItem from './Fooditem'
import "./Fooddisplay.css";
import img3 from '../components/assets/menuempty.png';

const FoodDisplay = ({ category }) => {
    const { food_list } = useContext(Storecontext);
    const filteredItems = food_list.filter(item =>
        (category === "All" || category === item.category) && item.available
    );
    

    return (
        <div>
            {filteredItems.length === 0 ? (
                <div className="empty-state">
                    <text >Empty for now, but delicious goodies coming soon! Stay tuned!</text>
                    <img src={img3} alt='' />
                </div>
            ) : (
                <div className='food-display' id='food-display'>
                    {filteredItems.map((item, index) => (
                        <FoodItem key={index} id={item._id} name={item.name} price={item.price} image={item.image} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default FoodDisplay; // Corrected component name in export
