import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '../src/pages/home';
import LoginPage from './pages/Login';
import Navbar from './components/Navbar';
import Footer from './components/footer';
import Announcement from './components/Announcement';
import Cart from './pages/cart';
import Newmenu from './pages/newmenu';
import Placeorder from './pages/Placeorder';
import Ordersuccess from './pages/Ordersuccess';
import Aboutus from './pages/Aboutus';
import Review from './pages/Rewiew';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogout = () => {
    setIsLoggedIn(false); 
  };

  return (
    <Router>
      <div className='app'>
        <Announcement/>
        <Navbar  isLoggedIn={isLoggedIn} onLogout={handleLogout} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/cart" element={<Cart isLoggedIn={isLoggedIn}  />} />
          <Route path="/new" element={<Newmenu />} />
          <Route path="/address" element={<Ordersuccess />} />
          <Route path="/order" element={<Placeorder />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/review" element={<Review />} />
          
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
