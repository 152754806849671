import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import img1 from '../components/assets/insta.png';
import img2 from '../components/assets/face.png';

const moveRightToLeft = keyframes`
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-50%);
    }
`;

// Styled Footer component
const StyledFooter = styled.footer`
  height: 40vh;
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
  position: relative; /* Set position to relative */
  overflow-x: hidden;
`;


const AndhraRuchulu = styled.h1`
  position: absolute;
  bottom: -0px; 
  font-size: ${props => props.fontSize}px;
  font-family: 'Inter', sans-serif; 
  color: rgba(252, 252, 253, 0.2); 
  margin: 0; 
  padding: 0; 
  line-height: 0.8; 
  overflow: hidden; 
  animation: ${moveRightToLeft} 5s linear infinite; 
  animation-delay: ${props => props.delay || '0s'}; 
  
`;

const Container = styled.div`
  display: flex;
  margin-left:5%;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
`;

const Container2 = styled.div`
  display: flex;
  align-items: center;
  justify-content:center;
  width: 35px; 
  height: 35px; 
  border-radius: 50%; 
  background-color: #fff;
  cursor:pointer;
  transition: background-color 0.3s; 
  &:hover {
    background: linear-gradient(45deg, #FF8C28, var(--yellow));
    img {
      filter: invert(100%);
    }
  }

  @media only screen and (max-width: 500px) {
    display:none;
  }
`;

const Container3 = styled.div`
  display: flex;
  gap:10px;
  margin-top:10%;
  @media only screen and (max-width: 500px) {
    gap:8px;
  }
`;


const Container5 = styled.div`
display:none;

  @media only screen and (max-width: 500px) {
    display: flex;
    align-items: center;
    justify-content:center;
    width: 14px; 
    height: 14px; 
    img {
      filter: invert(100%);
    }
  }
`;


const Desc = styled.text`
  font-size: 18px;
  letter-spacing: 1px;
  color: #ffffff;
  margin-top: 5px;
  cursor: pointer;
  text-align: start;
  @media only screen and (max-width: 800px) {
    font-size: 10px;
  }
`;

const Desc1 = styled.text`
  font-size: 18px;
  letter-spacing: 1px;
  color: #ffffff;
  margin-top: 5px;
  text-align: start;
  
  @media only screen and (max-width: 800px) {
    font-size: 10px;
  }
`;


const Title1 = styled.h1`
  font-size: 24px;
  color: #fff; 
  margin-top: 10px;
  @media only screen and (max-width: 800px) {
    font-size: 12px;
  }
`;

const CenteredContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Footer content
const Footer = () => {
  const navigate = useNavigate();

  const handlemenuClick = () => {
    navigate('/new');
  };

  const handlemenuClick1 = () => {
    navigate('/cart');
  };
  const handleClick = () => {
    navigate('/aboutus');
  };

  return (
    <StyledFooter>
      <p>&copy; 2024 Your Company. All rights reserved.</p>
      <div style={{ display: "flex", justifyContent: 'start' }}>

        <Container>
          <Title1>
            About
          </Title1>
          <Desc onClick={handleClick}>About Us
          </Desc>
          <Container3>
            <Container2>
              <img src={img1} alt='' height='18px'></img>
            </Container2>
            <Container2>
              <img src={img2} alt='' height='18px'></img>
            </Container2>
          </Container3>
          <Container3>
            <Container5>
              <img src={img1} alt='' height='12px'></img>
            </Container5>
            <Container5>
              <img src={img2} alt='' height='12px'></img>
            </Container5>
          </Container3>
        </Container>
        <Container>
          <Title1>
            Support
          </Title1>
          <Desc onClick={handlemenuClick} >
            Menu
          </Desc>
          <Desc onClick={handlemenuClick1} >
            Cart
          </Desc>
        </Container>
        <Container>
          <Title1>
            Contact Us
          </Title1>
          <Desc1>
            0899825938
          </Desc1>
          <Desc1>
            Email: team@andhraruchulu.in
          </Desc1>
        </Container>
      </div>
      <CenteredContent>
        <AndhraRuchulu fontSize={100}>Andhra Ruchulu</AndhraRuchulu>
      </CenteredContent>
    </StyledFooter>
  );
};

export default Footer;
