import React, { useEffect } from 'react'
import styled, { keyframes } from 'styled-components';
import "../components/Navbar.css";
import TextField from '@mui/material/TextField';
import { useState } from "react";
import img2 from '../components/assets/b.png';
import img3 from '../components/assets/br.png';
import img4 from '../components/assets/dosa.png';
import img5 from '../components/assets/briyanimob.png';
import img6 from '../components/assets/chickenmobile.jpg';
import img7 from '../components/assets/dosamobile.jpg';
import img8 from '../components/assets/location.png';
import Randombox from '../components/Box';
import { useNavigate } from 'react-router-dom';
import Lottie from "lottie-react";
import Flow from "../Flow.json";


const Container = styled.div`
  height: calc(95vh - 60px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin-top:7%;
    margin-bottom: 15%;
  }
  
`;


const Container1 = styled.div`
height: calc(95vh - 60px);
width: 80%;
align-items: center;
justify-content: center;
margin-left: 10%;
margin-right:10%;
`;
const Container2 = styled.div`
height: 5%;
width: 100%;
`;
const Box = styled.div`
width: 25%;
height: 350px;
background-color: rgba(254, 161, 80, 0.1);
border-radius: 15px;
transition: transform 0.3s ease-in-out;
display: flex; 
justify-content: center; 
align-items: center;
box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
perspective: 1000px; /* Sets perspective for 3D effect */
overflow: hidden; /* Prevents content from overflowing container */
display: flex; 

&:hover {
  transform: rotateX(15deg) rotateY(12deg) scale(1.1); 
}
@media (max-width: 800px) {
  display:none;
}
`;

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Box1 = styled.div`
  width: 25%;
  height: 350px;
  background-color: rgba(254, 161, 80, 0.1);
  border-radius: 15px;
  margin-right: 12.5%;
  transition: transform 0.3s ease-in-out;
  display: flex; 
  justify-content: center; 
  align-items: center;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);

  &:hover {
    transform: rotateX(15deg) rotateY(12deg) scale(1.1); 
  }
  @media (max-width: 800px) {
    display:none;
  }
`;

const Boxphone = styled.div`
  width: 100%;
  height: 70vh;
  margin-top:30px;
  background-color: rgba(254, 161, 80, 0.1);
  border-radius: 15px;
  transition: transform 0.3s ease-in-out;
  display: flex; 
  justify-content: center; 
  align-items: center;
  flex-direction: column;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);

  &:hover {
    transform: rotateX(15deg) rotateY(12deg) scale(1.1); 
  }
  @media (min-width: 800px) {
    display:none;
  }
`;

const Boxphoneimg = styled.div`
width: 80%;
height: 15vh;
margin-top: 30px;
background-image: url(${img5});;
background-size: cover;
background-position: center;
background-repeat: no-repeat;
border-radius: 15px;
transition: transform 0.3s ease-in-out;
box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
`;

const Boxphoneimg1 = styled.div`
width: 80%;
height: 15vh;
margin-top: 30px;
background-image: url(${img6});;
background-size: cover;
background-position: center;
background-repeat: no-repeat;
border-radius: 15px;
transition: transform 0.3s ease-in-out;
box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
`;
const Boxphoneimg2 = styled.div`
width: 80%;
height: 15vh;
margin-top: 30px;
background-image: url(${img7});;
background-size: cover;
background-position: center;
background-repeat: no-repeat;
border-radius: 15px;
transition: transform 0.3s ease-in-out;
box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
`;





const ImgContainer = styled.div`
  position: relative;
`;

const Img3 = styled.img`
  transition: transform 0.5s ease-in-out; 
  ${Box1}:hover & {
    animation: ${rotateAnimation} 10s linear infinite; 
  }
`;
const Img4 = styled.img`
  transition: transform 0.5s ease-in-out; 
  ${Box1}:hover & {
    animation: ${rotateAnimation} 10s linear infinite; 
  }
`;
const Img5 = styled.img`
  transition: transform 0.5s ease-in-out; 
  ${Box}:hover & {
    animation: ${rotateAnimation} 10s linear infinite; 
  }
`;

const CircleOutline = styled.div`
  position: absolute;
  border: 1px double #FEA150; 
  border-radius: 50%; /* Makes it a circle */
  width: 160px;
  height: 160px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
  
`;


const Container3 = styled.div`
height: calc(95vh - 60px);
width: 100%;
align-items: center;
justify-content: center;
`;

const Container4 = styled.div`
height: 20vh;
width: 100%;
`;


const Title = styled.h1`
  font-size: 40px;
  color: #000; 
  margin-bottom: -20px;
  @media only screen and (max-width: 800px) {
    margin-top: 15%;
    font-size: 20px;
  }
  span {
    color: #FEA150
  }
  
`;

const Title1 = styled.h1`
  font-size: 30px;
  color: #000; 
  text-align: center;
  margin-bottom: -20px;
  @media only screen and (max-width: 992px) {
    font-size: 18px;
  }
`;

const Desc = styled.p`
  font-size: 20px;
  letter-spacing: 2px;
  color: #000000;
  @media only screen and (max-width: 800px) {
    margin-top:20px;
    font-size: 14px;
  }
`;


const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justifyItems: center;
`;

const SearchInput = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 20,
    borderColor: '#ff5722',
    marginRight: '10px',
    border: "0.4",

    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FEA150',
      border: "0.1",

    },
  },
}));



const Home = ({ isLoggedIn }) => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [availability, setAvailability] = useState(true);
  const [helperText, setHelperText] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    const savedEIRCode = localStorage.getItem('eirCode');
    if (savedEIRCode) {
      setSearchValue(savedEIRCode);
      const firstThreeChars = savedEIRCode.toUpperCase().substring(0, 3);
      const isAvailable = firstThreeChars === 'C15';
      setAvailability(isAvailable);
      setHelperText(isAvailable ? 'We are delivering!' : 'Pickup only!');
    }
  }, []);


  const navigateToNewMenu = () => {
    navigate('/new', { state: { category: 'Briyani' } });
  };

  const navigateToNewMenus = () => {
    navigate('/new', { state: { category: 'Currys ' } });
  };

  const navigateToNewMenut = () => {
    navigate('/new', { state: { category: 'Tiffins' } });
  };

  const checkAvailability = () => {
    if (searchValue.trim() === '') {
      alert('Please enter a value before checking availability');
      return;
    }

    if (searchValue.length === 7 || searchValue.length === 8) {
      localStorage.setItem('eirCode', searchValue);
    } else {
      alert('EIR code should have a length of 7 or 8 characters');
      return;
    }

    const firstThreeChars = searchValue.toUpperCase().substring(0, 3);
    const isAvailable = firstThreeChars === 'C15';
    setAvailability(isAvailable);
    setHelperText(isAvailable ? 'We are delivering!' : 'Pickup only!');
  };

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
    setHelperText('');
  };

  return (
    <>

      <Container>
        <div className='hometext' >
          {isLoggedIn ? (
            <Title>
              Hi Name !<br />
              Welcome <span>biryani lovet's</span>
            </Title>
          ) : (
            <Title>
              Hi!<br />
              Welcome <span>biryani lover's</span>
            </Title>
          )}
          <Desc>Savour the best biryanis in Ireland...</Desc>
          <div className='search'>
            <SearchContainer>
              <SearchInput
                size="small"
                placeholder="Enter EIR code"
                variant="outlined"
                width='30%'
                value={searchValue}
                 onChange={handleInputChange}
                helperText={(searchValue.length === 7 || searchValue.length === 8) ? helperText : ''}
                inputProps={{ style: { textTransform: 'uppercase' } }}
              />

            </SearchContainer>
            <button onClick={checkAvailability}  >Check Availability</button>
          </div>
        </div>
        <div style={{ marginRight: '10%', marginLeft: '5%' }}>
          <Lottie className='imagecontainer' animationData={Flow} loop={true} />
        </div>
      </Container>

      <Container1>
        <Title1>
          What we offer
        </Title1>
        <div className='popularfood' >
          <Boxphone>
            <Boxphoneimg onClick={navigateToNewMenu} />
            <text onClick={navigateToNewMenu} style={{ marginTop: '10px', marginBottom: '-10px' }}>Briyani</text>
            <Boxphoneimg1 onClick={navigateToNewMenus} />
            <text onClick={navigateToNewMenus} style={{ marginTop: '10px', marginBottom: '-10px' }}>Currys </text>
            <Boxphoneimg2 onClick={navigateToNewMenut} />
            <text onClick={navigateToNewMenut} style={{ marginTop: '10px', marginBottom: '20px' }}>Tiffins</text>
          </Boxphone>
          <Box1 onClick={navigateToNewMenu}>
            <div>
              <ImgContainer>
                <Img3 src={img2} height='150px' alt='' />
                <CircleOutline />
              </ImgContainer>
              <Desc style={{ marginTop: '20px', fontSize: '20px', textAlign: 'center' }}>Briyani</Desc>

            </div>
          </Box1>
          <Box1 onClick={navigateToNewMenus}>
            <div>
              <ImgContainer>
                <Img4 src={img3} height='150vh' alt='' />
                <CircleOutline />
              </ImgContainer>
              <Desc style={{ marginTop: '20px', fontSize: '20px', textAlign: 'center' }}>Currys </Desc>
            </div>

          </Box1>
          <Box onClick={navigateToNewMenut}>
            <div>
              <ImgContainer>
                <Img5 src={img4} width='150vh' height='150vh' alt='' />
                <CircleOutline />
              </ImgContainer>
              <Desc style={{ marginTop: '20px', fontSize: '20px', textAlign: 'center' }}>Tiffins</Desc>
            </div>

          </Box>
        </div>
      </Container1>
      <Container3>
        <Title1>
          Discover the buzz around town! Hear it straight from the community.
        </Title1>
        <Container2 />
        <Randombox />
        <Container2 />
      </Container3>
      <Container4 />


    </>
  )
}

export default Home
