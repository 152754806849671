import React, { useEffect, useState, useContext } from 'react';
import "./Login.css";
import styled from 'styled-components';
import { Storecontext } from '../contex/Storecontext';

const StyledSpan = styled.span`
    color: #FF8C28;
    cursor: pointer;
`;


const LoginPage = () => {
    const [currstate, setCurrstate] = useState('Login');
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        email: ''
    });
    const { addmutliplecart } = useContext(Storecontext);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const changehandler = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const login = async () => {
        if (navigator.onLine) {
            console.log("Login successful", formData)
            let responsedata;
            await fetch('https://andhraruchulu.onrender.com/login', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData),
            })
                .then((response) => response.json())
                .then((data) => responsedata = data)
                .catch((error) => console.error('Error:', error));

            if (responsedata.success) {
                localStorage.setItem('auth-token', responsedata.token);
                const storedCartItems = JSON.parse(localStorage.getItem('cart-items'));
                if (storedCartItems) {
                    const itemIds = Object.keys(storedCartItems);
                    addmutliplecart(itemIds);
                    localStorage.removeItem('cart-items');
                }
                goBackAndReload();
            }

            else {
                alert(responsedata.error);
            }
        } else {
            alert('You are offline. Please check your internet connection.');
        }
    }


    const signup = async () => {
        if (navigator.onLine) {
            console.log("Signup successful", formData)
            let responsedata;
            await fetch('https://andhraruchulu.onrender.com/signup', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData),
            }).then((response) => response.json()).then((data) => responsedata = data)

            if (responsedata.success) {
                localStorage.setItem('auth-token', responsedata.token);
                const storedCartItems = JSON.parse(localStorage.getItem('cart-items'));
                if (storedCartItems) {
                    const itemIds = Object.keys(storedCartItems);
                    addmutliplecart(itemIds);
                    localStorage.removeItem('cart-items');
                }
                goBackAndReload();
            }
            else {
                alert(responsedata.errors)
            }
        } else {
            alert('You are offline. Please check your internet connection.');
        }
    }

    const goBackAndReload = () => {
        window.history.go(-1);
        setTimeout(() => {
            window.location.reload();
        }, 500);
    }


    // const generateGuestToken = () => {
    //     const guestToken = Math.random().toString(36).substring(2);
    //     localStorage.setItem('guest-token', guestToken);
    //     window.location.replace("/");
    // }

    return (
        <div className='login-popup'>
            <div className='login-popup-container'>
                <i className='b1'></i>
                <i className='b3'></i>
                <div className='login'>
                    <h3>{currstate}</h3>
                    {currstate === 'Login' ? <></> : <input name='username' value={formData.username} onChange={changehandler} type="text" placeholder='Your name' required />}
                    <input name='email' value={formData.email} type="email" onChange={changehandler} placeholder='Your email' required />
                    <input name='password' value={formData.password} type="password" onChange={changehandler} placeholder='Password' required />
                    <button onClick={() => { currstate === "Login" ? login() : signup() }} className='button'>Continue</button>
                    <div style={{ display: 'flex', justifyContent: 'end', width: '100%', marginTop: '-10px' }}>
                        {/* <StyledText onClick={generateGuestToken}>Guest Mode</StyledText> */}
                        {currstate === "Login" ?
                            <StyledSpan onClick={() => setCurrstate("Sign Up")}>Sign Up</StyledSpan> :
                            <StyledSpan onClick={() => setCurrstate("Login")}>Login</StyledSpan>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginPage
