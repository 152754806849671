import React, { useContext } from 'react'
import styled from 'styled-components';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Storecontext } from '../contex/Storecontext';

// import {useMotionValue, useTransform} from 'framer-motion'

// const Desc = styled.text`
//   font-size: 12px;
 
//   color: #000000;
//   // @media only screen and (max-width: 993px) {
//   //   display:none;
//   // }
// `;

const Desc1 = styled.h3`
  font-size: 18px;
  letter-spacing: 2px;
  color: #000000;
  // @media only screen and (max-width: 993px) {
  //   display:none;
  // }
`;

const MenuItem = styled.div`
  margin-top: 20px;
  position: relative; 
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #FAF9F9;
  border-radius: 15px; 
  background-color: #FAF9F9; 
  transition: background-color 0.3s; 
  box-shadow: 1.5px 1.5px 4px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: rgba(254, 161, 80, 0.1);
    border: 1px solid rgba(254, 161, 80, 0.1);
    transform:scale(1.1) ; 
  }
`;

const ImgContainer = styled.div`
position: absolute;
width: 47%;
  height: 65%;
  top: -43px;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 800px) {
    width: 100px;
  height: 100px;
  }

  & img {
    width: 100%;
    height: auto;
    border-radius: 50%;
    display: block;
  }
  
  
  ${MenuItem}:hover & {
    border: 2px solid orange;
    border-radius: 50%;
    padding: 5px; 
  }
`;





const ItemName = styled.div`
  margin-top: 35%;
  font-weight: semi-bold;
  font-size: 18px;
  margin-bottom: 0.5vw;
  @media only screen and (max-width: 900px) {
    margin-top: 50px;
  }
  @media only screen and (max-width: 800px) {
    margin-top: 75px;
    margin-bottom: 10px;
  }
`;

const ItemDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; 
  width: 80%;
`;

const PlusButton = styled.button`
  border: none;
  height:25px;
  width:25px;
  border-radius: 50%;
  background-color: #ccc;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  ${MenuItem}:hover & {
    background: #FEA150;
    color: #fff; 
  }
`;

const PlusButtonadd = styled.button`
  border: none;
  height:auto;
  width:auto;
  border-radius: 20px;
  padding: 5px 10px;
  background-color: #ccc;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  ${MenuItem}:hover & {
    background: #FEA150;
    color: #fff; 
  }
`;

const PlusButtonr = styled.button`
  border: none;
  height:25px;
  width:25px;
  border-radius: 50%;
  background-color: #ccc;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  
`;


const Fooditem = ({ id, name, price,image }) => {
  const { cartItems, addToCart, removeFromCart } = useContext(Storecontext);
  // const x = useMotionValue(0);
  // const y = useMotionValue(0);
  // const rotatex =  useTransform(y, [-100,100],[30,-30]);
  // const rotatey = useTransform(x, [-100,100],[-30,30]);

  return (
    <div >
      <MenuItem >
        <ImgContainer>
          <img src={image} alt='' />
        </ImgContainer>
        <ItemName>{name}</ItemName>
        {/* <Divider style={{ width: '80%', marginBottom: '0.25vw', marginTop: '0.5vw' }} />
        <ItemDetails>
          <Desc>{calories} cal</Desc>
          <Desc>{persons} Persons </Desc>
        </ItemDetails> */}
        <Divider style={{ width: '80%', marginTop: '0.25vw' }} />
        <ItemDetails style={{ marginTop: '5%', marginBottom: '5%' }} >
          <Desc1>€{price}</Desc1>
          {!cartItems[id] ? (
            <PlusButtonadd onClick={() => addToCart(id)}>
              Add
            </PlusButtonadd>
          ) : (
            <div style={{ marginTop: '-1vw', marginBottom: '-1vw' }}>
              <div style={{  display:'flex', justifyContent: 'center', alignItems: 'center', }}>
                <PlusButtonr onClick={() => removeFromCart(id)}>
                  <RemoveIcon style={{ width: '15px' }} />
                </PlusButtonr>
                <p style={{ marginLeft: '5px', marginRight: '5px' }} >{cartItems[id]}</p>
                <PlusButton onClick={() => addToCart(id)}>
                  <AddIcon style={{ width: '15px' }} />
                </PlusButton>
              </div>
            </div>
          )}
        </ItemDetails>
      </MenuItem>

    </div>
  )
}

export default Fooditem
