export const menu_list = [
    {
        menu_name: 'Briyani'
    },
    {
        menu_name: 'Currys '
    },
    {
        menu_name: 'Tiffins'
    }
]

export const food_list = [
    { _id: 1, category: 'Briyani', name: 'Chicken Briyani', price: '15' },
    { _id: 2, category: 'Briyani', name: 'Mutton Briyani', price: '18' },
    { _id: 3, category: 'Currys ', name: 'Chicken 65', price: '10' },
    { _id: 4, category: 'Currys ', name: 'Gobi Manchurian', price: '12' },
    { _id: 5, category: 'Tiffins', name: 'Masala Dosa', price: '8' },
    { _id: 6, category: 'Tiffins', name: 'Idli', price: '6' },
    { _id: 7, category: 'Briyani', name: 'Vegetable Briyani', price: '14' },
    { _id: 8, category: 'Briyani', name: 'Egg Briyani', price: '16' },
    { _id: 9, category: 'Currys ', name: 'Paneer Tikka', price: '13' },
    { _id: 10, category: 'Currys ', name: 'Fish Fry', price: '20' },
    { _id: 11, category: 'Tiffins', name: 'Pongal', price: '7' },
    { _id: 12, category: 'Tiffins', name: 'Vada', price: '5' },
    { _id: 13, category: 'Briyani', name: 'Shrimp Briyani', price: '22' },
    { _id: 14, category: 'Briyani', name: 'Beef Briyani', price: '24' },
    { _id: 15, category: 'Currys ', name: 'Samosa', price: '9' },
    { _id: 16, category: 'Currys ', name: 'Onion Rings', price: '11' },
    { _id: 17, category: 'Tiffins', name: 'Uttapam', price: '8' },
    { _id: 18, category: 'Tiffins', name: 'Poori', price: '6' },
    { _id: 19, category: 'Briyani', name: 'Lamb Briyani', price: '20' },
    { _id: 20, category: 'Briyani', name: 'Vegetable Pulao', price: '12' },
    { _id: 21, category: 'Currys ', name: 'Chilli Chicken', price: '18' },
    { _id: 22, category: 'Currys ', name: 'Spring Rolls', price: '15' },
    { _id: 23, category: 'Tiffins', name: 'Appam', price: '9' },
    { _id: 24, category: 'Tiffins', name: 'Upma', price: '7' },
    { _id: 25, category: 'Briyani', name: 'Turkey Briyani', price: '25' }
];


