import { createContext, useEffect, useState } from "react";

export const Storecontext = createContext(null)

const StorecontextProvider = (props) => {

    const [cartItems, setcartItems] = useState(() => {
        const storedCartItems = localStorage.getItem('cart-items');
        return storedCartItems ? JSON.parse(storedCartItems) : {};
    });
    const [food_list, setFood_list] = useState([]);

    useEffect(() => {
        if (navigator.onLine) {
            fetch('https://andhraruchulu.onrender.com/allproducts')
                .then((response) => response.json())
                .then((data) => setFood_list(data));

            if (localStorage.getItem('auth-token')) {
                fetch('https://andhraruchulu.onrender.com/getcart', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/form-data',
                        'auth-token': `${localStorage.getItem('auth-token')}`,
                        'Content-Type': 'application/json'
                    },
                    body: "",
                })
                    .then((response) => response.json())
                    .then((data) => setcartItems(prevCartItems => ({ ...prevCartItems, ...data }))); // Merge fetched data with previous state
            }
        } else {
            alert('You are offline. Please check your internet connection.');
        }
    }, []);


    const addToCart = (itemId) => {
        if (!cartItems[itemId]) {
            setcartItems((prev) => ({ ...prev, [itemId]: 1 }))
        }
        else {
            setcartItems((prev) => ({ ...prev, [itemId]: prev[itemId] + 1 }))
        }
        console.log(itemId)
        if (localStorage.getItem('auth-token')) {
            fetch('https://andhraruchulu.onrender.com/addtocart', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'auth-token': localStorage.getItem('auth-token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ itemId: itemId })
            })
                .then((response) => response.json())
                .then((data) => console.log(data))
        } else {
            const updatedCartItems = { ...cartItems };
            updatedCartItems[itemId] = updatedCartItems[itemId] ? updatedCartItems[itemId] + 1 : 1;
            setcartItems(updatedCartItems);
            localStorage.setItem('cart-items', JSON.stringify(updatedCartItems));
        }
    }

    const addmutliplecart = (itemIds) => {
        if (localStorage.getItem('auth-token')) {
            fetch('https://andhraruchulu.onrender.com/addmutliplecart', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'auth-token': localStorage.getItem('auth-token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ items: itemIds }) 
            })
            .then((response) => response.json())
            .then((data) => console.log(data))
            .catch((error) => console.error('Error:', error));
        } 
    }
    


    const removeFromCart = (itemId) => {
        setcartItems((prev) => ({ ...prev, [itemId]: prev[itemId] - 1 }))
        if (localStorage.getItem('auth-token')) {
            fetch('https://andhraruchulu.onrender.com/removefromcart', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'auth-token': localStorage.getItem('auth-token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ itemId: itemId })
            })
                .then((response) => response.json())
                .then((data) => console.log(data))
        } else {
            const updatedCartItems = { ...cartItems };
            if (updatedCartItems[itemId] > 0) {
                updatedCartItems[itemId] -= 1;
                localStorage.setItem('cart-items', JSON.stringify(updatedCartItems));
                setcartItems(updatedCartItems);
            }
        }
    }

    const getTotalCartAmount = () => {
        let totalAmount = 0;
        if (food_list.length > 0) {
            for (const item in cartItems) {
                if (cartItems[item] > 0) {
                    let itemInfo = food_list.find((product) => product._id === Number(item));
                    if (itemInfo) {
                        totalAmount += itemInfo.price * cartItems[item];
                    }
                }
            }
        }
        return totalAmount;
    };


    const getTotalCartItems = () => {
        let totalItems = 0;
        for (const item in cartItems) {
            totalItems += cartItems[item];
        }
        return totalItems;
    };

    const contextValue = {
        food_list,
        cartItems,
        addmutliplecart,
        setcartItems,
        addToCart,
        removeFromCart,
        getTotalCartAmount,
        getTotalCartItems

    }

    return (
        <Storecontext.Provider value={contextValue}>
            {props.children}
        </Storecontext.Provider>
    )
}

export default StorecontextProvider;