import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    height: 30px;
    background-color: rgba(254, 161, 80, 0.2);
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    @media only screen and (max-width: 800px) {
        height: 50px;
      }
`;

const Announcement = () => {
    const [announcement, setAnnouncement] = useState('');

    useEffect(() => {
        fetchAnnouncement();
    }, []);

    const fetchAnnouncement = async () => {
        try {
            const response = await fetch('https://andhraruchulu.onrender.com/announcement');
            const data = await response.json();
            if (data.success) {
                setAnnouncement(data.message);
            }
        } catch (error) {
            console.error('Error fetching announcement:', error);
        }
    };

    return (
        announcement ? <Container>
            <div style={{ marginLeft: '10%', marginRight: '10%', textAlign: 'center' }}>
                <text style={{ fontSize: '14px' }}> {announcement}</text>
            </div>
        </Container> : null
    );
};

export default Announcement;
