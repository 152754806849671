import React from 'react';
import styled, { keyframes } from 'styled-components';
import img3 from '../components/assets/profilec.png';


const moveRightToLeft = keyframes`
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
`;

const getRandomPastelColor = () => {
    const pastelColors = [
        'rgba(194, 240, 240, 0.4)', // Light blue with reduced opacity
        'rgba(250, 219, 216, 0.4)', // Light pink with reduced opacity
        'rgba(255, 255, 179, 0.4)', // Light yellow with reduced opacity
        'rgba(224, 235, 235, 0.4)', // Light gray with reduced opacity
        'rgba(215, 240, 200, 0.4)', // Light green with reduced opacity
        'rgba(240, 194, 194, 0.4)', // Light red with reduced opacity
        'rgba(216, 250, 219, 0.4)', // Light cyan with reduced opacity
        'rgba(179, 179, 255, 0.4)', // Light purple with reduced opacity
        'rgba(240, 240, 194, 0.4)', // Light olive with reduced opacity
        'rgba(240, 216, 240, 0.4)', // Light lavender with reduced opacity
        'rgba(240, 227, 194, 0.4)', // Light peach with reduced opacity

    ];
    return pastelColors[Math.floor(Math.random() * pastelColors.length)];
};

const BoxContainer = styled.div`
    display: flex;
    height: 80vh;
    width: 100%;
    margin-top: 1%;
    overflow: hidden; /* Hide overflowing content */
    &:hover div {
        animation-play-state: paused; /* Pause animation on hover */
    }
`;

const Box = styled.div`
    width: 20%;
    min-width: 250px; /* Added minimum width */
    height: 220px;
    background-color: ${getRandomPastelColor()};
    border-radius: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left:20px;
    margin-right:20px;
    animation: ${moveRightToLeft} 10s linear infinite; /* Adjust duration as needed */
    animation-delay: ${props => props.delay || '0s'}; /* Add animation delay */
`;
const Box3 = styled.div`
    width: 20%;
    min-width: 250px; /* Added minimum width */
    height: 220px;
    background-color: ${getRandomPastelColor()};
    border-radius: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left:20px;
    margin-right:20px;
    animation: ${moveRightToLeft} 10s linear infinite; /* Adjust duration as needed */
    animation-delay: ${props => props.delay || '0s'}; /* Add animation delay */
`;

const Box1 = styled.div`
    width: 22%;
    min-width: 270px; /* Added minimum width */
    height: 180px;
    background-color: ${getRandomPastelColor()};
    border-radius: 15px;
    margin-top: 40px;
    margin-bottom: 20px;
    margin-right:20px;
    animation: ${moveRightToLeft} 10s linear infinite; /* Adjust duration as needed */
    animation-delay: ${props => props.delay || '0s'}; /* Add animation delay */
`;

const Box2 = styled.div`
    width: 22%;
    min-width: 270px; /* Added minimum width */
    height: 200px;
    background-color: ${getRandomPastelColor()};
    border-radius: 15px;
    margin-bottom: 20px;
    margin-right:20px;
    animation: ${moveRightToLeft} 10s linear infinite; /* Adjust duration as needed */
    animation-delay: ${props => props.delay || '0s'}; /* Add animation delay */
`;
const Box4 = styled.div`
    width: 22%;
    min-width: 225px; /* Added minimum width */
    height: 200px;
    background-color: ${getRandomPastelColor()};
    margin-top: 10px;
    border-radius: 15px;
    margin-bottom: 20px;
    margin-right:20px;
    animation: ${moveRightToLeft} 10s linear infinite; /* Adjust duration as needed */
    animation-delay: ${props => props.delay || '0s'}; /* Add animation delay */
`;
const Box5 = styled.div`
    width: 22%;
    min-width: 225px; /* Added minimum width */
    height: 220px;
    background-color: ${getRandomPastelColor()};
    border-radius: 15px;
    margin-bottom: 20px;
    margin-right:20px;
    animation: ${moveRightToLeft} 10s linear infinite; /* Adjust duration as needed */
    animation-delay: ${props => props.delay || '0s'}; /* Add animation delay */
`;

const Box6 = styled.div`
    width: 22%;
    min-width: 250px; /* Added minimum width */
    height: 220px;
    background-color: ${getRandomPastelColor()};
    border-radius: 15px;
    margin-top: 130px;
    margin-bottom: 20px;
    margin-right:20px;
    animation: ${moveRightToLeft} 10s linear infinite; /* Adjust duration as needed */
    animation-delay: ${props => props.delay || '0s'}; /* Add animation delay */
`;
const Box7 = styled.div`
    width: 22%;
    min-width: 225px; /* Added minimum width */
    height: 200px;
    background-color: ${getRandomPastelColor()};
    margin-top: 10px;
    border-radius: 15px;
    margin-bottom: 20px;
    margin-right:20px;
    animation: ${moveRightToLeft} 10s linear infinite; /* Adjust duration as needed */
    animation-delay: ${props => props.delay || '0s'}; /* Add animation delay */
`;
const Box8 = styled.div`
    width: 22%;
    min-width: 225px; /* Added minimum width */
    height: 220px;
    background-color: ${getRandomPastelColor()};
    border-radius: 15px;
    margin-bottom: 20px;
    margin-right:20px;
    animation: ${moveRightToLeft} 10s linear infinite; /* Adjust duration as needed */
    animation-delay: ${props => props.delay || '0s'}; /* Add animation delay */
`;

const Circle = styled.div`
    width: 50px;
    height: 50px;
    background-color: #ffffff;
    border-radius: 50%;
    margin-left: 20px;
    background-image: url(${img3}); 
    background-size: cover;
    background-position: center;
`;



const Title1 = styled.h1`
  font-size: 15px;
  color: #000; 
  margin-top: 10px;
  margin-left: 20px;
  @media only screen and (max-width: 992px) {
    font-size: 12px;
  }
`;

const Desc = styled.p`
  font-size: 11px;
  letter-spacing: 1px;
  color: #000000;
  margin-top: -20px;
`;

const Title2 = styled.h1`
  font-size: 15px;
  color: #000; 
  margin-top: 10px;
  @media only screen and (max-width: 992px) {
    font-size: 12px;
  }
`;

const Container3 = styled.div`
height:10px;
width: 100%;
`;

const App = () => {
    const reviews = [
        {
            name: 'Prasanth Roy',
            text: 'Authentic Andhra flavors. A must-visit for traditional cuisine lovers.'
        },
        {
            name: 'Sreenivasa Prasad',
            text: 'Delightful find! Delicious biryani and dosas. Highly recommended for foodies.'
        },
        {
            name: 'Bhavi',
            text: 'Never disappoints! Wide variety, generous portions. Perfect for food enthusiasts.'
        },
        {
            name: 'Hima Bindu',
            text: 'Favorite spot for South Indian cuisine lovers. Flavorful and vibrant ambiance.'
        },
        {
            name: 'Lavanya',
            text: 'Culinary delight! Wide range of flavorful dishes. A must-visit for foodies!'
        },
        {
            name: 'Karthik Reddy',
            text: 'Authentic Andhra flavors. Every dish is a treat!'
        },
        {
            name: 'Ravi',
            text: 'Culinary gem! Delicious food, friendly staff.'
        },
        {
            name: 'Uma Mahesh',
            text: 'Unforgettable experience! Delicious food, inviting ambiance.'
        },
        {
            name: 'Akhil',
            text: 'Authentic Andhra flavors. Every dish is a treat!'
        },
    ];

    return (
        <BoxContainer >
            <div >
                <Box>
                    <Container3 />
                    <Circle />
                    <Title1>"</Title1>
                    <div style={{ marginLeft: '30px', marginRight: '10px' }} >
                        <Desc>{reviews[0].text}</Desc>
                        <Title2>{reviews[0].name}</Title2>
                    </div>
                </Box>
                <Box3>
                    <Container3 />
                    <Circle />
                    <Title1>"</Title1>
                    <div style={{ marginLeft: '30px', marginRight: '10px', marginTop: '-0px' }} >
                        <Desc>{reviews[1].text}</Desc>
                        <Title2>{reviews[1].name}</Title2>
                    </div>
                </Box3>
            </div>
            <div >
                <Box1>
                    <Container3 />
                    <Circle />
                    <Title1>"</Title1>
                    <div style={{ marginLeft: '30px', marginRight: '10px', marginTop: '-0px' }} >
                        <Desc>{reviews[2].text}</Desc>
                        <Title2>{reviews[2].name}</Title2>
                    </div>
                </Box1>
                <Box2>
                    <Container3 />
                    <Circle />
                    <Title1>"</Title1>
                    <div style={{ marginLeft: '30px', marginRight: '10px', marginTop: '-0px' }} >
                        <Desc>{reviews[3].text}</Desc>
                        <Title2>{reviews[3].name}</Title2>
                    </div></Box2>
            </div>
            <div >
                <Box4>
                    <Container3 />
                    <Circle />
                    <Title1>"</Title1>
                    <div style={{ marginLeft: '30px', marginRight: '10px', marginTop: '-0px' }} >
                        <Desc>{reviews[4].text}</Desc>
                        <Title2>{reviews[4].name}</Title2>
                    </div>
                </Box4>
                <Box5>
                    <Container3 />
                    <Circle />
                    <Title1>"</Title1>
                    <div style={{ marginLeft: '30px', marginRight: '10px', marginTop: '-0px' }} >
                        <Desc>{reviews[5].text}</Desc>
                        <Title2>{reviews[5].name}</Title2>
                    </div>
                </Box5>
            </div>
            <div >
                <Box6>
                    <Container3 />
                    <Circle />
                    <Title1>"</Title1>
                    <div style={{ marginLeft: '30px', marginRight: '10px', marginTop: '-0px' }} >
                        <Desc>{reviews[6].text}</Desc>
                        <Title2>{reviews[6].name}</Title2>
                    </div>
                </Box6>
            </div>
            <div>
                <Box7>
                    <Container3 />
                    <Circle />
                    <Title1>"</Title1>
                    <div style={{ marginLeft: '30px', marginRight: '10px', marginTop: '-0px' }} >
                        <Desc>{reviews[7].text}</Desc>
                        <Title2>{reviews[7].name}</Title2>
                    </div>
                </Box7>
                <Box8>
                    <Container3 />
                    <Circle />
                    <Title1>"</Title1>
                    <div style={{ marginLeft: '30px', marginRight: '10px', marginTop: '-0px' }} >
                        <Desc>{reviews[8].text}</Desc>
                        <Title2>{reviews[8].name}</Title2>
                    </div>
                </Box8>
            </div>
        </BoxContainer>
    );
};

export default App;
