import React, { useEffect } from 'react'
import './Ordersuccess.css'
import img3 from '../components/assets/conform.png';
import styled from 'styled-components';

const Title = styled.text`
  font-size: 40px;
  color: #000; 
  margin-bottom: 20px;
  @media only screen and (max-width: 992px) {
    font-size: 30px;
  }
  span {
    color: #FEA150
  }
`;

const Ordersuccess = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className='orderbox'>
      <Title>
        Order placed <span>Thank You !</span>
      </Title>
      {localStorage.getItem('type') === 'pickup' ? <text>we will send you the pick up location for your email address</text> : <></>}
      
      <img src={img3} alt='' style={{ width: '20%', marginTop: '20px' }} />

    </div>
  )
}

export default Ordersuccess
